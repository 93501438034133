import React from "react";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Formik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";

import cognito from "../../cognitoConfig";
import { useSetScreenName } from "../../hooks";
import classes from "./ForgotPassword.module.css";
import formClasses from "../Form/form.module.css";

const ForgotPassword = () => {
  document.title = 'WaterBear | Forgot Password';
  useSetScreenName("Forgot Password");
  let labels = {
    email: false,
  };

  const history = useHistory();

  const setActive = (text, label) => {
    if (text !== "") {
      labels[label] = true;
    } else {
      labels[label] = false;
    }
  };

  return (
    <div className="section">
      <h1>Forgot password?</h1>
      <p>
        No worries, confirm your email address and we’ll send you the
        instructions.
      </p>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email("Invalid email")
            .min(2, "Minimum of 2 Characters")
            .max(70, "Maximum of 70 Characters")
            .required("Required"),
        })}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          setSubmitting(true);
          const userPool = {
            Username: values.email,
            Pool: cognito,
          };
          const cognitoUser = new CognitoUser(userPool);

          cognitoUser.forgotPassword({
            onSuccess: function (result) {
              setSubmitting(false);
            },
            onFailure: function (err) {
              if (err.code === "UserNotFoundException") {
                setErrors({ email: "Email Address Not Found" });
                history.push("/noaccount");
              } else {
                setErrors({ email: err.message });
              }
              setSubmitting(false);
            },
            inputVerificationCode() {
              history.push({
                pathname: "/newPassword",
                state: { email: values.email },
              });
            },
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setErrors,
        }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <div className={formClasses.floatingLabel}>
                <input
                  type="text"
                  name="email"
                  onChange={(e) => {
                    handleChange(e);
                    setActive(e.target.value, "email");
                  }}
                  onBlur={handleBlur}
                  value={values.email}
                  className={
                    errors.email && touched.email ? formClasses.inputError : ""
                  }
                />
                <label className={labels.email ? formClasses.labelActive : ""}>
                  Email
                </label>
                {errors.email && touched.email ? (
                  <div className={formClasses.errorMsg}>{errors.email}</div>
                ) : null}
              </div>

              <button
                className={`${classes.btn} ${formClasses.submit}`}
                type="submit"
                disabled={isSubmitting}
              >
                Reset Password
              </button>

              <button
                onClick={() => {
                  history.push("/signIn");
                }}
                className={formClasses.cancel}
                disabled={isSubmitting}
              >
                Cancel
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ForgotPassword;
