import { CognitoUserPool } from "amazon-cognito-identity-js";
import AWS from 'aws-sdk';

export const REGION = process.env.REACT_APP_REGION
export const USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
export const IDENTITY_POOL_ID = process.env.REACT_APP_IDENTITY_POOL_ID
export const USER_POOL_URL = `cognito-idp.${REGION}.amazonaws.com/${USER_POOL_ID}`
export const COGNITO_LOGIN_URL = process.env.REACT_APP_COGNITO_LOGIN_URL
export const COGNITO_REDIRECT_URL = process.env.REACT_APP_COGNITO_REDIRECT_URL


AWS.config.update({
  region: REGION
})

const poolData = {
  UserPoolId: USER_POOL_ID,
  ClientId: CLIENT_ID
}

const cognito = new CognitoUserPool(poolData);

export default cognito;
