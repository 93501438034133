import React from "react";
import { withRouter } from "react-router-dom";
import classes from "./Nav.module.css";
import navLogo from "../../assets/logo-horizontal@2x.svg";

const Nav = (props) => {

  return (
    <div className={classes.navbar}>
      <nav>
        <div className={classes.main_nav}>
          <div className={classes.nav_left}>
            <a href="/">
              <img src={navLogo} alt="WaterBear logo" />
            </a>
          </div>
          {window.location.pathname === "/" ? (
            <div className={classes.nav_right}>
              <p className={classes.nav_right_txt}>Already have an account?</p>
              <button
                className={classes.nav_btn}
                onClick={() => {
                  props.history.push("/signIn");
                }}
              >
                <span className={classes.register_txt}>SignIn</span>
              </button>
              
            </div>
          ) : (
            <div className={classes.nav_right}>
              <p className={classes.nav_right_txt}>
                Don’t have an account yet?
              </p>
              <button
                className={classes.nav_btn}
                onClick={() => {
                  props.history.push("/");
                }}
              >
                <span className={classes.register_txt}>Register</span>
              </button>
            </div>
          )}
        </div>
      </nav>
    </div>
  );
};

export default withRouter(Nav);
