import React from "react";
import { useHistory } from "react-router-dom";
import { CognitoUser } from "amazon-cognito-identity-js";
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import cognito from "../../cognitoConfig";
import { useSetScreenName } from "../../hooks";
import classes from "./confirmEmail.module.css";
import formClasses from "../Form/form.module.css";
import successIcon from "../../assets/icons/icon-success.png";

const ConfirmEmail = (props) => {
  document.title = 'WaterBear | Confirm Email';
  useSetScreenName("Confirm Email");
  const history = useHistory();
  let email = "Your Email";

  if (props.location.state !== undefined) {
    email = props.location.state.email;
  }

  const resendLinkToast = () => {
    toast.success('Confirmation email has been resent', {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      });
  }
  const resendLink = () => {
    if (props.location.state !== undefined) {
      email = props.location.state.email;
    }
    const userPool = {
      Username: email,
      Pool: cognito,
    };
    const cognitoUser = new CognitoUser(userPool);
    cognitoUser.resendConfirmationCode(function (err, result) {
      if (err) {
        console.log(err.message || JSON.stringify(err));
        return;
      } else {
        resendLinkToast()
      }
    });
  };

  return (
    <div className="section">
      <img src={successIcon} alt="Success Icon"></img>
      <h1>Good to go</h1>
      <p>
        You successfully created an account. An email with an activation link
        was sent to <strong>{email}</strong> Welcome to Waterbear.
      </p>
      <div className={classes.btnContainer}>
        <button
          onClick={() => {
            history.push("/signIn");
          }}
          className={`${classes.btn} ${formClasses.submit}`}
        >
          Sign In
        </button>
        <button
          onClick={() => {
            resendLink();
          }}
          className={`${classes.btn} ${formClasses.cancel}`}
        >
          Resend Link
        </button>
      </div>
      <ToastContainer toastClassName={classes.notification} />
    </div>
  );
};

export default ConfirmEmail;
