import React from "react";
import AWS from "aws-sdk";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { Formik } from "formik";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";

import cognito, {
  USER_POOL_URL,
  IDENTITY_POOL_ID,
  COGNITO_LOGIN_URL,
  COGNITO_REDIRECT_URL,
} from "../../cognitoConfig";
import { useSetScreenName } from "../../hooks";
import { AnalyticsUtils } from "../../utils";
import classes from "./signIn.module.css";
import formClasses from "../Form/form.module.css";

const SignIn = () => {
  document.title = "WaterBear | Sign In";
  useSetScreenName("Sign In");

  // localStorage.clear();
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query = useQuery();

  let labels = {
    username: false,
    password: false,
  };

  // const history = useHistory();

  const setActive = (text, label) => {
    if (text !== "") {
      labels[label] = true;
    } else {
      labels[label] = false;
    }
  };

  const saveRedirectUrl = () => {
    const redirectUrlInUrl = query.get("redirect_url");
    if (redirectUrlInUrl) {
      localStorage.setItem('redirectUrl',redirectUrlInUrl)
    }
  }

  const getRedirectUrl = () => {
    const redirectUrlInStorage = localStorage.getItem("redirectUrl");
    const redirectUrlInUrl = query.get("redirect_url");
    if (redirectUrlInStorage) {
      return redirectUrlInStorage;
    } else if (redirectUrlInUrl) {
      return redirectUrlInUrl;
    } else {
      // Default Redirect URL
      return COGNITO_REDIRECT_URL;
    }
  };

  saveRedirectUrl()


  return (
    <div className="section">
      <h1>Sign In</h1>

      <Formik
        initialValues={{ username: "", password: "" }}
        validationSchema={Yup.object({
          username: Yup.string().required("Required").email("Invalid email"),
          password: Yup.string().required("Required"),
        })}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          setSubmitting(true);
          const userPool = {
            Username: values.username,
            Pool: cognito,
          };
          const formValues = {
            Username: values.username,
            Password: values.password,
          };

          const authenticationDetails = new AuthenticationDetails(formValues);

          const cognitoUser = new CognitoUser(userPool);

          const loggedIn = new Promise((success, error) => {
            cognitoUser.authenticateUser(authenticationDetails, {
              onSuccess: (result) => {
                const idToken = result.getIdToken().getJwtToken();

                const loginsObj = {
                  [USER_POOL_URL]: idToken,
                };

                AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                  IdentityPoolId: IDENTITY_POOL_ID,
                  Logins: loginsObj,
                });
                AWS.config.credentials.refresh((error) => {
                  if (error) {
                    console.error("Error: ", error);
                  } else {
                    AnalyticsUtils.sendGtmAccountEvent(
                      "Log In",
                      "Account Log In"
                    );
                    window.location.replace(
                      `${COGNITO_LOGIN_URL}?jwt=${idToken}&redirect=${getRedirectUrl()}`
                    );

                    localStorage.clear();
                    success(result);
                    setSubmitting(false);
                  }
                });
              },
              onFailure: (err) => {
                console.log(`Error ${err}`);
                error(err);
                setSubmitting(false);
              },
            });
          });

          loggedIn.catch((e) => {
            //Catch and Display Errors.
            if (e.code === "UserNotFoundException") {
              setErrors({ username: e.message });
            } else if (e.code === "NotAuthorizedException") {
              setErrors({ password: e.message });
            } else if (e.code === "UserNotConfirmedException") {
              setErrors({
                username:
                  "Please check your emails and verify your account before logging in.",
              });
            } else {
              setErrors({ username: e.message });
            }
            setSubmitting(false);
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setSubmitting,
          setErrors,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div className={formClasses.floatingLabel}>
              <input
                type="text"
                name="username"
                onChange={(e) => {
                  handleChange(e);
                  setActive(e.target.value, "username");
                }}
                onBlur={handleBlur}
                value={values.username}
                className={
                  errors.username && touched.username
                    ? formClasses.inputError
                    : ""
                }
              />
              <label className={labels.username ? formClasses.labelActive : ""}>
                Email
              </label>
              {errors.username && touched.username ? (
                <div className={formClasses.errorMsg}>{errors.username}</div>
              ) : null}
            </div>

            <div className={formClasses.floatingLabel}>
              <input
                type="password"
                name="password"
                onChange={(e) => {
                  handleChange(e);
                  setActive(e.target.value, "password");
                }}
                onBlur={handleBlur}
                value={values.password}
                className={
                  errors.password && touched.password
                    ? formClasses.inputError
                    : ""
                }
              />
              <label className={labels.password ? formClasses.labelActive : ""}>
                Password
              </label>
              {errors.password && touched.password ? (
                <div className={formClasses.errorMsg}>{errors.password}</div>
              ) : null}
            </div>

            <div className={formClasses.row}>
              <label className={formClasses.checkbox}>
                Remember Me!
                <input type="checkbox" name="rememberMe" />
                <span className={formClasses.checkmark}></span>
              </label>

              <a className={classes.ForgotPassword} href="./forgotPassword">
                Forgot Password?
              </a>
            </div>

            <button
              className={formClasses.submit}
              type="submit"
              disabled={isSubmitting}
            >
              Sign In
            </button>

            {/* {console.log(errors)} */}
          </form>
        )}
      </Formik>
    </div>
  );
};

export default SignIn;
