import React from "react";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Formik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import  qs from 'qs'
import cognito from "../../cognitoConfig";
import { useSetScreenName } from "../../hooks";
import formClasses from "../Form/form.module.css";
import classes from "./NewPassword.module.css";
import successIcon from "../../assets/icons/icon-success.png";

const NewPassword = (props) => {
  document.title = 'WaterBear | New Password';
  useSetScreenName("New Password");
  let labels = {
    verificationCode: false,
    password: false,
    confirmPassword: false,
  };

  const history = useHistory();

  const reset_code = qs.parse(props.location.search, { ignoreQueryPrefix: true }).reset_code;

  const setActive = (text, label) => {
    if (text !== "") {
      labels[label] = true;
    } else {
      labels[label] = false;
    }
  };

  return (
    <div className="section">
      <img src={successIcon} alt="Success Icon"></img>
      <h1>Reset Password</h1>
      <p>
        {/* We’ve sent you a password reset code. Enter the Rest code and new
        password below.
         */}

         Enter the new password below:
      </p>
      <Formik
        initialValues={{ password: "", verificationCode: "" }}
        validationSchema={Yup.object({
          password: Yup.string()
            .matches(
              // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
              /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
              // "Must Contain 8 Characters, With One Uppercase, Lowercase, Number"
              "Password must contain at least 8 characters, one uppercase, one number and one special character"
            )
            .required("Required"),
          // verificationCode: Yup.string().required("Required"),
        })}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          setSubmitting(true);

          let email = "";
          if (props.location.state !== undefined) {
            email = props.location.state.email;
          }

          const userPool = {
            Username: email,
            Pool: cognito,
          };
          const cognitoUser = new CognitoUser(userPool);

          cognitoUser.confirmPassword(
            values.verificationCode.toString(),
            values.password,
            {
              onSuccess: function (result) {
                history.push({ pathname: "/signIn" });
                setSubmitting(false);
              },
              onFailure: function (err) {
                if (err.code === "InvalidParameterException") {
                  setErrors({ verificationCode: "Reset Code Invalid" });
                } else {
                  setErrors({ verificationCode: err.message });
                }
                setSubmitting(false);
              },
            }
          );
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setErrors,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className={formClasses.floatingLabel}>
              <input
                // type="number"
                type="hidden"
                name="verificationCode"
                onChange={(e) => {
                  handleChange(e);
                  setActive(e.target.value, "verificationCode");
                }}
                onBlur={handleBlur}
                // value={values.verificationCode}
                value={reset_code}
                className={
                  errors.verificationCode && touched.verificationCode
                    ? formClasses.inputError
                    : ""
                }
              />

              <label
                className={
                  labels.verificationCode ? formClasses.labelActive : ""
                }
              >
                
              </label>
              {errors.verificationCode && touched.verificationCode ? (
                <div className={formClasses.errorMsg}>
                  {errors.verificationCode}
                </div>
              ) : null}
            </div>

            <div className={formClasses.floatingLabel}>
              <input
                type="password"
                name="password"
                onChange={(e) => {
                  handleChange(e);
                  setActive(e.target.value, "password");
                }}
                onBlur={handleBlur}
                value={values.password}
                className={
                  errors.password && touched.password
                    ? formClasses.inputError
                    : ""
                }
              />

              <label className={labels.password ? formClasses.labelActive : ""}>
                Password
              </label>
              {errors.password && touched.password ? (
                <div className={formClasses.errorMsg}>{errors.password}</div>
              ) : null}
            </div>

            <button
              className={`${classes.btn} ${formClasses.submit}`}
              type="submit"
              disabled={isSubmitting}
            >
              Confirm Password
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default NewPassword;
