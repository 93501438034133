import React from "react";
// import { CognitoUser } from 'amazon-cognito-identity-js';
// import { Formik } from 'formik';
// import * as Yup from 'yup';
import { useHistory } from "react-router-dom";

// import cognito from '../../cognitoConfig';
import { useSetScreenName } from "../../../hooks";
import formClasses from "../../Form/form.module.css";
import classes from "./AccountNotFound.module.css";
import failureIcon from "../../../assets/icons/icon-failure.png";

const AccountNotFound = (props) => {
  document.title = 'WaterBear | Account Not Found';
  useSetScreenName("Account Not Found");
  const history = useHistory();
  return (
    <div className="section">
      <img src={failureIcon} alt="Success Icon"></img>
      <h1>No such account, unfortunately</h1>
      <p className={classes.msg}>
        The email address you provided is not connected to any account.
      </p>

      <div className={classes.btnContainer}>
        <button
          onClick={() => {
            history.push("/forgotPassword");
          }}
          className={`${classes.btn} ${formClasses.submit}`}
        >
          Try Again
        </button>
        <button
          onClick={() => {
            history.push("/");
          }}
          className={`${classes.btn} ${formClasses.cancel}`}
        >
          Register
        </button>
      </div>
    </div>
  );
};

export default AccountNotFound;
