import React, { useEffect, useRef } from "react";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { Formik } from "formik";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";

import cognito, { COGNITO_REDIRECT_URL } from "../../cognitoConfig";
import { useSetScreenName } from "../../hooks";
import { AnalyticsUtils } from "../../utils";
import formClasses from "../Form/form.module.css";

const private_policy_url = `${COGNITO_REDIRECT_URL}/privacy-policy`;

const terms_conditions_url = `${COGNITO_REDIRECT_URL}terms-conditions`;

const useTrackUnmounted = () => {
  const isUnmounted = useRef(false);

  useEffect(() => {
    return () => {
      isUnmounted.current = true;
    };
  }, [isUnmounted]);

  return isUnmounted;
};

const SignUp = (props) => {
  document.title = "WaterBear | Register";

  const isUnmounted = useTrackUnmounted();
  useSetScreenName("Register");
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query = useQuery();
  let labels = {
    given_name: false,
    family_name: false,
    email: false,
    password: false,
  };

  const setActive = (text, label) => {
    if (text !== "") {
      labels[label] = true;
    } else {
      labels[label] = false;
    }
  };

  const initRedirectUrl = () => {
    const redirectUrl = query.get("redirect_url");

    if (redirectUrl !== null) {
      localStorage.setItem('redirectUrl',redirectUrl)
    }
  }
  initRedirectUrl();

  const initialEmail = () => {
    const email = query.get("email");

    if (email !== null) {
      setActive(email, "email");
      return email;
    }
    return "";
  };

  return (
    <div className="section">
      <h1>Register account</h1>
      <Formik
        initialValues={{
          email: initialEmail(),
          password: "",
          given_name: "",
          family_name: "",
          private_policy: false,
          marketing_optin: false,
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email("Invalid email")
            .min(2, "Minimum of 2 Characters")
            .max(70, "Maximum of 70 Characters")
            .required("Required"),
          password: Yup.string()
            .matches(
              /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z]).{8,}$/,
              "Must Contain 8 Characters, With One Uppercase, Lowercase, Number"
            )
            .required("Required"),
          given_name: Yup.string()
            .required("Required")
            .min(2, "Minimum of 2 Characters")
            .max(70, "Maximum of 70 Characters"),
          family_name: Yup.string()
            .required("Required")
            .min(2, "Minimum of 2 Characters")
            .max(70, "Maximum of 70 Characters"),
          private_policy: Yup.boolean().oneOf(
            [true],
            "Must Agree to Privacy Policy"
          ),
        })}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          const cognitoData = [];

          cognitoData.push(
            new CognitoUserAttribute({
              Name: "given_name",
              Value: values.given_name,
            })
          );

          cognitoData.push(
            new CognitoUserAttribute({
              Name: "family_name",
              Value: values.family_name,
            })
          );

          // marketing_optin confirmation
          cognitoData.push(
            new CognitoUserAttribute({
              Name: "custom:marketing_optin",
              Value: values.marketing_optin.toString(),
            })
          );
          // device type
          cognitoData.push(
            new CognitoUserAttribute({
              Name: "custom:device_type",
              Value: "AXONISTA_WEB",
            })
          );

          cognito.signUp(
            values.email,
            values.password,
            cognitoData,
            null,
            (err, result) => {
              if (err) {
                console.log("error", err);

                if (err.code === "UsernameExistsException") {
                  setErrors({ email: err.message });
                } else {
                  setErrors({ email: err.message });
                }
              } else {
                AnalyticsUtils.sendGtmAccountEvent(
                  "Sign Up",
                  "Sign Up Completion"
                );
                
                props.history.push(`/confirmEmail${props.location.search}`, {
                  email: values.email,
                  cognitoData: cognitoData,
                });
              }

              if (!isUnmounted.current) {
                setSubmitting(false);
              }
            }
          );
          // A way to set the isSubmitting function in Formik
          // https://jaredpalmer.com/formik/docs/api/formik#issubmitting-boolean
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setErrors,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div className={formClasses.formGroup}>
              <div className={formClasses.floatingLabel}>
                <input
                  type="text"
                  name="given_name"
                  onChange={(e) => {
                    handleChange(e);
                    setActive(e.target.value, "given_name");
                  }}
                  onBlur={handleBlur}
                  value={values.given_name}
                  className={
                    errors.given_name && touched.given_name
                      ? formClasses.inputError
                      : ""
                  }
                />
                <label
                  className={labels.given_name ? formClasses.labelActive : ""}
                >
                  Name
                </label>
                {errors.given_name && touched.given_name ? (
                  <div className={formClasses.errorMsg}>
                    {errors.given_name}
                  </div>
                ) : null}
              </div>

              <div className={formClasses.floatingLabel}>
                <input
                  type="text"
                  name="family_name"
                  onChange={(e) => {
                    handleChange(e);
                    setActive(e.target.value, "family_name");
                  }}
                  onBlur={handleBlur}
                  value={values.family_name}
                  className={
                    errors.family_name && touched.family_name
                      ? formClasses.inputError
                      : ""
                  }
                />
                <label
                  className={labels.family_name ? formClasses.labelActive : ""}
                >
                  Last Name
                </label>
                {errors.family_name && touched.family_name ? (
                  <div className={formClasses.errorMsg}>
                    {errors.family_name}
                  </div>
                ) : null}
              </div>
            </div>

            {/* {values.email !== '' ? touched.email = true: !touched.email }
                        {console.log(values.email)}
                        {console.log(touched)} */}
            <div className={formClasses.floatingLabel}>
              <input
                type="text"
                name="email"
                onChange={(e) => {
                  handleChange(e);
                  setActive(e.target.value, "email");
                }}
                onBlur={handleBlur}
                value={values.email}
                className={
                  errors.email && touched.email ? formClasses.inputError : ""
                }
              />
              <label className={labels.email ? formClasses.labelActive : ""}>
                Email
              </label>
              {errors.email && touched.email ? (
                <div className={formClasses.errorMsg}>{errors.email}</div>
              ) : null}
            </div>

            <div className={formClasses.floatingLabel}>
              <input
                type="password"
                name="password"
                onChange={(e) => {
                  handleChange(e);
                  setActive(e.target.value, "password");
                }}
                onBlur={handleBlur}
                value={values.password}
                className={
                  errors.password && touched.password
                    ? formClasses.inputError
                    : ""
                }
              />

              <label className={labels.password ? formClasses.labelActive : ""}>
                Password
              </label>
              {errors.password && touched.password ? (
                <div className={formClasses.errorMsg}>{errors.password}</div>
              ) : null}
            </div>

            <label className={formClasses.checkbox}>
              I agree to WaterBear's{" "}
              <a href={terms_conditions_url} target="blank">
                terms and conditions
              </a>{" "}
              and{" "}
              <a href={private_policy_url} target="blank">
                Privacy policy
              </a>
              <input
                type="checkbox"
                name="private_policy"
                value="true"
                checked={values.private_policy}
                onChange={handleChange}
              />
              <span className={formClasses.checkmark}></span>
              {errors.private_policy && touched.private_policy ? (
                <div className={formClasses.errorMsg}>
                  {errors.private_policy}
                </div>
              ) : null}
            </label>

            <label
              className={`${formClasses.checkbox} ${formClasses.bottomCheckbox}`}
            >
              I hereby agree that WaterBear will communicate with me via the
              supplied email.
              <input
                type="checkbox"
                name="marketing_optin"
                value="true"
                checked={values.marketing_optin}
                onChange={handleChange}
              />
              <span className={formClasses.checkmark}></span>
              {errors.marketing_optin && touched.marketing_optin ? (
                <div className={formClasses.errorMsg}>
                  {errors.marketing_optin}
                </div>
              ) : null}
            </label>

            <button
              className={formClasses.submit}
              type="submit"
              disabled={isSubmitting}
            >
              Create account
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default SignUp;
