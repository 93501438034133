const sendGtmEvent = (event, action, label) => {
  // push to Tag Manager's Data Layer
  // additional config in Tag Manager required to trigger Google Analytics events
  // https://www.analyticsmania.com/post/google-tag-manager-custom-event-trigger/
  window.dataLayer.push({
    event,
    action,
    label,
  });
};

const sendGtmAccountEvent = (action, label) => {
  sendGtmEvent("Account", action, label);
};

export { sendGtmEvent, sendGtmAccountEvent };
