import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import * as serviceWorker from "./serviceWorker";

import Nav from "./components/Nav/Nav";
import SignUp from "./components/SignUp/SignUp";
import SignIn from "./components/SignIn/SignIn";
import ConfirmEmail from "./components/ConfirmEmail/ConfirmEmail";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import NewPassword from "./components/NewPassword/NewPassword";
import AccountNotFound from "./components/ForgotPassword/AccountNotFound/AccountNotFound"
import PageNotFound from "./components/404";
import "./index.css";

const routing = (
  <React.Fragment>
    <Router>
      <Nav></Nav>
      <Switch>
        <Route exact path="/" component={SignUp} />
        <Route exact path="/signIn" component={SignIn} />
        <Route path="/confirmEmail" component={ConfirmEmail} />
        <Route exact path="/forgotPassword" component={ForgotPassword} />
        <Route exact path="/noaccount" component={AccountNotFound} />
        <Route exact path="/newPassword" component={NewPassword} />
        <Route component={PageNotFound} />
      </Switch>
    </Router>
  </React.Fragment>
);

ReactDOM.render(routing, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
